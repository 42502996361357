<div (click)="_preventBubblingFromLabel($event)"
     [class.mdc-form-field--align-end]="labelPosition == 'before'"
     class="mdc-form-field">
  <div #checkbox class="mdc-checkbox">
    <!--    [attr.aria-checked]="_getAriaChecked()" -->
    <input #input
           (blur)="_onBlur()"
           (change)="_onInteractionEvent($event)"
           (click)="_onInputClick()"

           [attr.aria-describedby]="ariaDescribedby"
           [attr.aria-label]="ariaLabel || null"
           [attr.aria-labelledby]="ariaLabelledby"
           [attr.name]="name"
           [attr.value]="value"
           [checked]="checked"
           [class.mdc-checkbox--selected]="checked"
           [disabled]="disabled"
           [id]="inputId"
           [required]="required"
           [tabIndex]="tabIndex"
           class="mdc-checkbox__native-control"
           type="checkbox"/>
    <div class="mdc-checkbox__background">
      <div *ngIf="!disabled && checked && !indeterminate" class="mdc-checkbox__icon mdc-checkbox__icon--checked"></div>
      <div *ngIf="disabled && checked && !indeterminate" class="mdc-checkbox__icon mdc-checkbox__icon--disabled"></div>
      <div *ngIf="indeterminate" class="mdc-checkbox__icon mdc-checkbox__icon--indeterminate"></div>
    </div>
  </div>
  <label #label [for]="inputId" class="mdc-checkbox__label">
    <ng-content/>
  </label>
</div>
