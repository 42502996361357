import { NgIf } from '@angular/common';
import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'aim-checkbox-v2',
  templateUrl: './aim-checkbox-v2.component.html',
  styleUrls: ['./aim-checkbox-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AimCheckboxV2Component),
      multi: true
    }
  ],
  host: {
    class: 'aim-checkbox-v2'
  },
  standalone: true,
  imports: [NgIf]
})
export class AimCheckboxV2Component extends MatCheckbox {
  @HostBinding('class.light') @Input() public isLight = false;
  @HostBinding('class.error') @Input() public error = false;
}
